<template>
  <v-app>
    <navigation-drawer v-if="isAuthenticated" />
    <app-bar v-if="isAuthenticated" />
    <v-main class="blue-grey lighten-5">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import AppBar from '@/views/layout/AppBar.vue'
import NavigationDrawer from '@/views/layout/NavigationDrawer.vue'

export default {
  name: 'App',
  components: {
    AppBar,
    NavigationDrawer,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
    }),
  },
}
</script>
