<template>
  <v-navigation-drawer
    app
    clipped
    permanent
    :mini-variant="$vuetify.breakpoint.mdAndDown"
    :expand-on-hover="$vuetify.breakpoint.mdAndDown"
  >
    <v-list>
      <div
        v-for="menu in menus"
        :key="menu.title"
      >
        <v-list-item
          v-if="hasRole(...menu.roles)"
          :to="menu.to"
          link
        >
          <v-list-item-icon>
            <v-icon>
              {{ menu.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ menu.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SideMenu',
  data() {
    return {
      menus: [
        {
          title: 'Clients',
          to: '/clients',
          roles: ['api_owner', 'super_admin', 'customer_support', 'user'],
          icon: 'mdi-account-group',
        },
        {
          title: 'Products',
          to: '/products',
          roles: ['api_owner', 'super_admin', 'user'],
          icon: 'mdi-car',
        },
        {
          title: 'Types',
          to: '/products-types',
          roles: ['api_owner', 'super_admin'],
          icon: 'mdi-cog',
        },
        {
          title: 'Users',
          to: '/users',
          roles: ['api_owner', 'super_admin'],
          icon: 'mdi-account-multiple',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
  },
}
</script>
