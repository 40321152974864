'use strict'

import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

// Full config:  https://github.com/axios/axios#request-config
let config = {
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 1000,
  withCredentials: false,
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      let accessToken = store.getters['auth/getAccessToken'] || null
      if (accessToken) {
        config.headers['Authorization'] = accessToken
      }
      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    },
)

_axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response
    },
    function (error) {
      // Do something with response error

      if (error.config && error.response?.status === 401) {
        if (error.config.url.endsWith('oauth/token/refresh')) {
          localStorage.clear()
          document.location.href = '/'
        }
        return store.dispatch('auth/refreshToken').then(() => {
          error.config.headers['Authorization'] = store.getters['auth/getAccessToken']
          return _axios.request(error.config);
        });
      }

      return Promise.reject(error)
    },
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
