const state = {
  user: JSON.parse(localStorage.getItem('user') || '{}'),
  roles: {
    api_owner: 5,
    customer_support: 4,
    client: 3,
    user: 2,
    super_admin: 1,
  },
}

const mutations = {
  setUser(state, user) {
    state.user = user
    localStorage.user = JSON.stringify(user)
  },
}

const actions = {
  async fetch({ commit }) {
    let { data: user } = await this._vm.axios.get('/profile-authenticate')
    commit('setUser', user.data)
  },
}

const getters = {
  hasRole(state) {
    return (...searchRoles) => {
      let roleIds = []
      for (let searchRole in searchRoles) {
        roleIds.push(state.roles[searchRoles[searchRole]])
      }
      return roleIds.includes(state.user.profile_id)
    }
  },
  isAuthenticated(state) {
    return state.user.id !== undefined && state.user.active === true
  },
  getUser() {
    return state.user
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
