import Vue from 'vue'
import dayjs from 'dayjs'

const Plugin = {
  install(Vue, options) {
    Vue.prototype.$date = dayjs

    Vue.filter('date', (value, template = 'DD/MM/YYYY HH:mm:ss') => {
      if (value) {
        return dayjs(value).format(template)
      }
    })
  },
}

Vue.use(Plugin)

export default Plugin
