const state = {
  auth: JSON.parse(localStorage.getItem('auth') || '{}'),
}

const mutations = {
  setAuth(state, auth) {
    state.auth = auth
    localStorage.auth = JSON.stringify(auth)
  },
}

const actions = {
  async login({ commit }, { username, password }) {
    let data = await this._vm.axios.post('/oauth/token', {
      'grantType': 'password',
      'clientId': process.env.VUE_APP_CLIENT_ID,
      'clientSecret': process.env.VUE_APP_CLIENT_SECRET,
      'username': username,
      'password': password,
      'scope': null,
    })
    commit('setAuth', data.data)
    await this.dispatch('user/fetch')
  },
  async refreshToken({ commit, getters }) {
    let data = await this._vm.axios.post('/oauth/token/refresh', {
      'grantType': 'refresh_token',
      'clientId': process.env.VUE_APP_CLIENT_ID,
      'clientSecret': process.env.VUE_APP_CLIENT_SECRET,
      'refreshToken': getters.getRefreshToken,
      'scope': null,
    })
    commit('setAuth', data.data)
  },
}

const getters = {
  getAccessToken() {
    return `${state.auth.token_type} ${state.auth.access_token}`
  },
  getRefreshToken() {
    return state.auth.refresh_token
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
