import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/clients',
  },
  {
    path: '/login',
    name: 'auth.login',
    component: () => import('@/views/auth/Login.vue'),
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/Clients.vue'),
  },
  {
    path: '/clients/:id',
    name: 'clients.details',
    props: true,
    component: () => import(/* webpackChunkName: "clients" */ '@/views/ClientDetails.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '@/views/TechnicalReviews.vue'),
  },
  {
    path: '/products/:id',
    name: 'products.details',
    props: true,
    component: () => import(/* webpackChunkName: "products" */ '@/views/TechnicalReviewDetails.vue'),
  },
  {
    path: '/products-types',
    name: 'products-types',
    component: () => import(/* webpackChunkName: "config" */ '@/views/TechnicalReviewTypes.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "config" */ '@/views/Users.vue'),
  },
]

const router = new VueRouter({
  routes,
})

router.beforeResolve((to, from, next) => {
  if (to.name !== 'auth.login' && !store.getters['user/isAuthenticated']) {
    next({ name: 'auth.login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

export default router
