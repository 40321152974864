import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/store/modules/app'
import Auth from '@/store/modules/auth'
import User from '@/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //
  },
  mutations: {
    //
  },
  actions: {
    //
  },
  modules: {
    app: App,
    auth: Auth,
    user: User,
  },
})
