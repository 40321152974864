<template>
  <v-app-bar
    app
    clipped-left
    color="primary"
    dark
  >
    <v-toolbar-title>
      <router-link
        to="/"
        tag="span"
        style="cursor: pointer"
      >
        {{ title }}
      </router-link>
    </v-toolbar-title>
    <v-spacer />

    <div v-if="getUser === null">
      <v-btn
        to="/login"
        text
      >
        <span class="mr-2">Login</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </div>
    <div v-else>
      {{ getUser.first_name }}
      {{ getUser.last_name }}
      <v-btn
        icon
        @click="logout"
      >
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavBar',
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    }
  },
  computed: {
    ...mapGetters('user', ['getUser']),
  },
  methods: {
    logout() {
      localStorage.clear()
      document.location.href = '/'
    },
  },
}
</script>
