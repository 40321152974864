const state = {
  app_name: process.env.VUE_APP_TITLE || 'Digital Factory',
}

const mutations = {
  //
}

const actions = {
  //
}

const getters = {
  appName: (state) => state.app_name,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
